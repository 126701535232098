import React, { Component } from 'react';
import { array, bool, func, oneOf, object, shape, string, arrayOf } from 'prop-types';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { useHistory, useLocation } from 'react-router-dom';
import omit from 'lodash/omit';
import classNames from 'classnames';

import { useConfiguration } from '../../context/configurationContext';
import { useRouteConfiguration } from '../../context/routeConfigurationContext';

import { useIntl, intlShape, FormattedMessage } from '../../util/reactIntl';
import {
  isAnyFilterActive,
  isMainSearchTypeKeywords,
  isOriginInUse,
  getQueryParamNames,
} from '../../util/search';
import {
  NO_ACCESS_PAGE_USER_PENDING_APPROVAL,
  NO_ACCESS_PAGE_VIEW_LISTINGS,
  parse,
} from '../../util/urlHelpers';
import { createResourceLocatorString } from '../../util/routes';
import { ATHLETE, ATHLETESEARCH, BRAND, BRANDSEARCH, GET_SEARCH_TYPE_WITH_TAB, PROMOTION, PROMOTIONSEARCH, propTypes } from '../../util/types';
import {
  isErrorNoViewingPermission,
  isErrorUserPendingApproval,
  isForbiddenError,
} from '../../util/errors';
import { hasPermissionToViewData, isUserAuthorized } from '../../util/userHelpers';
import { getListingsById } from '../../ducks/marketplaceData.duck';
import { manageDisableScrolling, isScrollingDisabled } from '../../ducks/ui.duck';

import { Button, H3, H5, NamedRedirect, Page, PrimaryButton, SecondaryButton } from '../../components';
import TopbarContainer from '../TopbarContainer/TopbarContainer';
import FooterContainer from '../FooterContainer/FooterContainer';

import {
  groupListingFieldConfigs,
  initialValues,
  searchParamsPicker,
  validUrlQueryParamsFromProps,
  validFilterParams,
  cleanSearchFromConflictingParams,
  createSearchResultSchema,
  pickListingFieldFilters,
  omitLimitedListingFieldParams,
} from './SearchPage.shared';

import FilterComponent from './FilterComponent';
import MainPanelHeader from './MainPanelHeader/MainPanelHeader';
import SearchFiltersMobile from './SearchFiltersMobile/SearchFiltersMobile';
import SortBy from './SortBy/SortBy';
import SearchResultsPanel from './SearchResultsPanel/SearchResultsPanel';
import NoSearchResultsMaybe from './NoSearchResultsMaybe/NoSearchResultsMaybe';

import css from './SearchPage.module.css';
import TopbarSearchForm from '../TopbarContainer/Topbar/TopbarSearchForm/TopbarSearchForm';

const MODAL_BREAKPOINT = 768; // Search is in modal on mobile layout

// SortBy component has its content in dropdown-popup.
// With this offset we move the dropdown a few pixels on desktop layout.
const FILTER_DROPDOWN_OFFSET = -14;

export class SearchPageComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      search: props.searchParams,
      isMobileModalOpen: false,
      currentQueryParams: validUrlQueryParamsFromProps(props),
    };

    this.onOpenMobileModal = this.onOpenMobileModal.bind(this);
    this.onCloseMobileModal = this.onCloseMobileModal.bind(this);

    // Filter functions
    this.resetAll = this.resetAll.bind(this);
    this.getHandleChangedValueFn = this.getHandleChangedValueFn.bind(this);

    // SortBy
    this.handleSortBy = this.handleSortBy.bind(this);

    // Athlete and Brand Search
    this.athleteSearch = this.athleteSearch.bind(this);
    this.promotionSearch = this.promotionSearch.bind(this);
    this.brandSearch = this.brandSearch.bind(this);

    // search location handle submit 
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  // Invoked when a modal is opened from a child component,
  // for example when a filter modal is opened in mobile view
  onOpenMobileModal() {
    this.setState({ isMobileModalOpen: true });
  }

  // Invoked when a modal is closed from a child component,
  // for example when a filter modal is opened in mobile view
  onCloseMobileModal() {
    this.setState({ isMobileModalOpen: false });
  }

  // Reset all filter query parameters
  resetAll(e) {
    const { history, routeConfiguration, config } = this.props;
    const { listingFields: listingFieldsConfig } = config?.listing || {};
    const { defaultFilters: defaultFiltersConfig } = config?.search || {};

    const urlQueryParams = validUrlQueryParamsFromProps(this.props);

    const filterQueryParamNames = getQueryParamNames(listingFieldsConfig, defaultFiltersConfig);

    // Reset state
    this.setState({ currentQueryParams: {} });

    // Reset routing params
    const queryParams = omit(urlQueryParams, filterQueryParamNames);
    history.push(createResourceLocatorString('SearchPage', routeConfiguration, {}, {}));
  }

  getHandleChangedValueFn(useHistoryPush) { 
    const { history, routeConfiguration, config } = this.props;
    const { listingFields: listingFieldsConfig } = config?.listing || {};
    const { defaultFilters: defaultFiltersConfig, sortConfig } = config?.search || {};
    const listingCategories = config.categoryConfiguration.categories;
    const filterConfigs = {
      listingFieldsConfig,
      defaultFiltersConfig,
      listingCategories,
    };

    const urlQueryParams = validUrlQueryParamsFromProps(this.props);

    return updatedURLParams => {
      const updater = prevState => {
        const { address, bounds, keywords } = urlQueryParams || {};
        const mergedQueryParams = { ...urlQueryParams, ...prevState.currentQueryParams };

        // Address and bounds are handled outside of MainPanel.
        // I.e. TopbarSearchForm && search by moving the map.
        // We should always trust urlQueryParams with those.
        // The same applies to keywords, if the main search type is keyword search.
        const keywordsMaybe = isMainSearchTypeKeywords(config) ? { keywords } : {};
        return {
          currentQueryParams: omitLimitedListingFieldParams(
            {
              ...mergedQueryParams,
              ...updatedURLParams,
              ...keywordsMaybe,
              address,
              bounds,
            },
            filterConfigs
          ),
        };
      };

      const callback = () => {
        const {searchParams} = this.props;
        const pub_listingType = searchParams.pub_listingType || {} 
        if (useHistoryPush) {
          const searchParams = this.state.currentQueryParams;
          const search = cleanSearchFromConflictingParams(searchParams, filterConfigs, sortConfig); 
          if (pub_listingType == ATHLETE) {
            history.push(createResourceLocatorString('SearchBasePage', routeConfiguration, { tab: ATHLETESEARCH }, search));
          } else if (pub_listingType == BRAND) {
            history.push(createResourceLocatorString('SearchBasePage', routeConfiguration, { tab: BRANDSEARCH }, search));
          } 
        }
      };

      this.setState(updater, callback);
    };
  }

  handleSortBy(urlParam, values) {
    const { history, routeConfiguration, searchParams } = this.props;
    const pub_listingType = searchParams.pub_listingType || {} 
    const TabName= GET_SEARCH_TYPE_WITH_TAB[pub_listingType]
    
    const urlQueryParams = validUrlQueryParamsFromProps(this.props);

    const queryParams = values
      ? { ...urlQueryParams, [urlParam]: values }
      : omit(urlQueryParams, urlParam);

    // history.push(createResourceLocatorString('SearchPage', routeConfiguration, {}, queryParams));
    history.push(createResourceLocatorString('SearchBasePage', routeConfiguration, { tab: TabName}, queryParams));
  }



  athleteSearch() {
    const { history, routeConfiguration } = this.props;
    const searchObj = {
      pub_listingType: "athlete"
    }
    this.setState({ currentQueryParams: { ...searchObj } })
    return history.push(createResourceLocatorString('SearchBasePage', routeConfiguration, { tab: ATHLETESEARCH }, {})); 
  }
  promotionSearch() {
    const { history, routeConfiguration } = this.props;
    const searchObj = {
      pub_listingType: "promotion"
    }
    this.setState({ currentQueryParams: { ...searchObj } })
    return history.push(createResourceLocatorString('SearchBasePage', routeConfiguration, { tab: PROMOTIONSEARCH }, {}));
  }
  brandSearch() {
    const { history, routeConfiguration } = this.props;
    const searchObj = {
      pub_listingType: "brand"
    }
    this.setState({ currentQueryParams: { ...searchObj } })
    return history.push(createResourceLocatorString('SearchBasePage', routeConfiguration, { tab: BRANDSEARCH }, {}));
  }
  // Reset all filter query parameters
  handleResetAll(e) {
    this.resetAll(e);

    // blur event target if event is passed
    if (e && e.currentTarget) {
      e.currentTarget.blur();
    }
  }

  handleSubmit(values) {
    const { currentSearchParams, searchParams} = this.props;
    const pub_listingType = searchParams.pub_listingType || {} 
    const TabName= GET_SEARCH_TYPE_WITH_TAB[pub_listingType]  
    const { history, config, routeConfiguration} = this.props; 
    const topbarSearchParams = () => {
      if (isMainSearchTypeKeywords(config)) {
        return { keywords: values?.keywords };
      }
      // topbar search defaults to 'location' search
      const { search, selectedPlace } = values?.location || {};
      const { origin, bounds } = selectedPlace || {};
      const originMaybe = isOriginInUse(config) ? { origin } : {};

      return {
        ...originMaybe,
        address: search,
        bounds,
      };
    };
    const newSearchParams = {
      ...currentSearchParams,
      ...topbarSearchParams(),
    };
    history.push(createResourceLocatorString('SearchBasePage', routeConfiguration, { tab: TabName}, newSearchParams));
  }
  
  render() {
    const {
      intl,
      listings,
      location,
      onManageDisableScrolling,
      pagination,
      scrollingDisabled,
      searchInProgress,
      searchListingsError,
      searchParams,
      routeConfiguration,
      config,
      currentUser,
      isAuthenticated
    } = this.props; 
      const isBlocked =  currentUser?.attributes?.profile?.
      publicData?.isBlocked || []
      const filterListing = listings.filter(listing=>!isBlocked.includes(listing.id.uuid))
      
    const userType = currentUser ? currentUser?.attributes?.profile?.publicData?.userType : "";
    const { mobilemenu, mobilesearch, keywords, address, origin, bounds } = parse(location.search, {
      latlng: ['origin'],
      latlngBounds: ['bounds'],
    });
    const { listingFields } = config?.listing || {};
    const { defaultFilters: defaultFiltersConfig, sortConfig } = config?.search || {};
    const activeListingTypes = config?.listing?.listingTypes.map(config => config.listingType);
    const marketplaceCurrency = config.currency;
    const categoryConfiguration = config.categoryConfiguration;
    const listingCategories = categoryConfiguration.categories;
    const listingFieldsConfig = pickListingFieldFilters({
      listingFields,
      locationSearch: location.search,
      categoryConfiguration,
    });
    const filterConfigs = {
      listingFieldsConfig,
      defaultFiltersConfig,
      listingCategories,
    };
    const { pub_listingType } = searchParams || {}
    
    // Page transition might initially use values from previous search
    // urlQueryParams doesn't contain page specific url params
    // like mapSearch, page or origin (origin depends on config.maps.search.sortSearchByDistance)
    const { searchParamsAreInSync, urlQueryParams, searchParamsInURL } = searchParamsPicker(
      location.search,
      searchParams,
      filterConfigs,
      sortConfig,
      isOriginInUse(config)
    );
    const validQueryParams = urlQueryParams;

    const isKeywordSearch = isMainSearchTypeKeywords(config);
    const builtInPrimaryFilters = defaultFiltersConfig.filter(f =>
      ['categoryLevel'].includes(f.key)
    );
    const builtInFilters = isKeywordSearch
      ? defaultFiltersConfig.filter(f => !['keywords', 'categoryLevel'].includes(f.key))
      : defaultFiltersConfig.filter(f => !['categoryLevel'].includes(f.key));
    const [customPrimaryFilters, customSecondaryFilters] = groupListingFieldConfigs(
      listingFieldsConfig,
      activeListingTypes
    );
    const availableFilters = [
      ...builtInPrimaryFilters,
      ...customPrimaryFilters,
      ...builtInFilters,
      ...customSecondaryFilters,
    ];

    // Selected aka active filters
    const selectedFilters = validQueryParams;
    const isValidDatesFilter =
      searchParamsInURL.dates == null ||
      (searchParamsInURL.dates != null && searchParamsInURL.dates === selectedFilters.dates);
    const keysOfSelectedFilters = Object.keys(selectedFilters);
    const selectedFiltersCountForMobile = isKeywordSearch
      ? keysOfSelectedFilters.filter(f => f !== 'keywords').length
      : keysOfSelectedFilters.length;

    const hasPaginationInfo = !!pagination && pagination.totalItems != null;
    const totalItems =
      searchParamsAreInSync && hasPaginationInfo
        ? pagination.totalItems
        : pagination?.paginationUnsupported
          ? filterListing.length
          : 0;
    const listingsAreLoaded =
      !searchInProgress &&
      searchParamsAreInSync &&
      !!(hasPaginationInfo || pagination?.paginationUnsupported);

    const conflictingFilterActive = isAnyFilterActive(
      sortConfig.conflictingFilters,
      validQueryParams,
      filterConfigs
    );

    const topbarSearcInitialValues = () => {
      if (isMainSearchTypeKeywords(config)) {
        return { keywords };
      }
      // Only render current search if full place object is available in the URL params
      const locationFieldsPresent = isOriginInUse(config)
        ? address && origin && bounds
        : address && bounds;
      return {
        location: locationFieldsPresent
          ? {
            search: address,
            selectedPlace: { address, origin, bounds },
          }
          : null,
      };
    };

    const sortBy = mode => {
      return sortConfig.active ? (
        <SortBy
          sort={validQueryParams[sortConfig.queryParamName]}
          isConflictingFilterActive={!!conflictingFilterActive}
          hasConflictingFilters={!!(sortConfig.conflictingFilters?.length > 0)}
          selectedFilters={selectedFilters}
          onSelect={this.handleSortBy}
          showAsPopup
          mode={mode}
          contentPlacementOffset={FILTER_DROPDOWN_OFFSET}
        />
      ) : null;
    };
    const noResultsInfo = (
      <NoSearchResultsMaybe
        listingsAreLoaded={listingsAreLoaded}
        totalItems={totalItems}
        location={location}
        resetAll={this.resetAll}
      />
    );

    const { title, description, schema } = createSearchResultSchema(
      filterListing,
      searchParamsInURL || {},
      intl,
      routeConfiguration,
      config
    );

    // Set topbar class based on if a modal is open in
    // a child component
    const topbarClasses = this.state.isMobileModalOpen
      ? classNames(css.topbarBehindModal, css.topbar)
      : css.topbar;

    // N.B. openMobileMap button is sticky.
    // For some reason, stickyness doesn't work on Safari, if the element is <button>
    return (
      <Page
        scrollingDisabled={scrollingDisabled}
        description={description}
        title={title}
        schema={schema}
      >
        <TopbarContainer rootClassName={topbarClasses} currentSearchParams={validQueryParams} />
        <div className={css.layoutWrapperContainer}>
          <aside className={css.layoutWrapperFilterColumn} data-testid="filterColumnAside">
            {/* Brand Button */}
            {userType !== BRAND && isAuthenticated ?
              <div>
                <Button className={pub_listingType == BRAND ? css.activeButton : css.inActiveButton} onClick={this.brandSearch}>
                  <FormattedMessage id="TopbarDesktop.campaigns" />
                </Button>
                {/* Promotion Button */}
                <Button className={pub_listingType == PROMOTION ? css.activeParmotionButton : css.inActiveParmotionButton} onClick={this.promotionSearch}>
                  <FormattedMessage id="TopbarDesktop.promotionSearchButton" />
                </Button>
              </div>
              : null}

            {/* Athlete Button  pub_listingType */}
            <Button className={pub_listingType == ATHLETE ? css.activeButton : css.inActiveButton} onClick={this.athleteSearch}>
              <FormattedMessage id="TopbarDesktop.athletes" />
            </Button>

            <br />
            <div className={css.filterColumnContent}>
              {availableFilters.map(filterConfig => {
                const key = `SearchFiltersDesktop.${filterConfig.scope || 'built-in'}.${filterConfig.key
                  }`;
                return (
                  <FilterComponent
                    pub_listingType={pub_listingType}
                    key={key}
                    userType={userType}
                    idPrefix="SearchFiltersDesktop"
                    className={css.filter}
                    config={filterConfig}
                    listingCategories={listingCategories}
                    marketplaceCurrency={marketplaceCurrency}
                    urlQueryParams={validQueryParams}
                    initialValues={initialValues(this.props, this.state.currentQueryParams)}
                    getHandleChangedValueFn={this.getHandleChangedValueFn}
                    intl={intl}
                    liveEdit
                    showAsPopup={false}
                    isDesktop
                  />
                );
              })}
              {pub_listingType == PROMOTION ? null : <>
                <div>
                  <button className={css.locationSearchButton} onClick={() => { this.setState({ isLocationSearch: !this.state.isLocationSearch }) }}>
                    <FormattedMessage id="SearchPage.location" />
                  </button>
                  <div className={css.locationSearchInput}>
                    {
                      this.state.isLocationSearch ?
                        <TopbarSearchForm
                          onSubmit={this.handleSubmit}
                          initialValues={topbarSearcInitialValues()}
                          isMobile
                          appConfig={config}
                          isLocationSearch={true}

                        /> : null
                    }
                  </div>
                </div>
                <button className={css.resetAllButton} onClick={e => this.handleResetAll(e)}>
                  <FormattedMessage id={'SearchFiltersMobile.resetAll'} />
                </button>
              </>}
            </div>
          </aside>

          <div className={css.layoutWrapperMain} role="main">
            <div className={css.searchResultContainer}>
              <SearchFiltersMobile
                className={css.searchFiltersMobileList}
                urlQueryParams={validQueryParams}
                sortByComponent={sortBy('mobile')}
                listingsAreLoaded={listingsAreLoaded}
                resultsCount={totalItems}
                searchInProgress={searchInProgress}
                searchListingsError={searchListingsError}
                showAsModalMaxWidth={MODAL_BREAKPOINT}
                onManageDisableScrolling={onManageDisableScrolling}
                onOpenModal={this.onOpenMobileModal}
                onCloseModal={this.onCloseMobileModal}
                resetAll={this.resetAll}
                selectedFiltersCount={selectedFiltersCountForMobile}
                isMapVariant={false}
                noResultsInfo={noResultsInfo}
              >
                <br />
                {userType !== BRAND && isAuthenticated ?
                  <div>
                    <Button className={pub_listingType == BRAND ? css.activeButton : css.inActiveButton} onClick={this.brandSearch}>
                      <FormattedMessage id="TopbarDesktop.campaigns" />
                    </Button>
                    {/* Promotion Button */}
                    <Button className={pub_listingType == PROMOTION ? css.activeParmotionButton : css.inActiveParmotionButton} onClick={this.promotionSearch}>
                      <FormattedMessage id="TopbarDesktop.promotionSearchButton" />
                    </Button>
                  </div>
                  : null} 
                <SecondaryButton className={pub_listingType == ATHLETE ? css.activeButton : css.inActiveButton} onClick={this.athleteSearch}>
                  <FormattedMessage id="TopbarDesktop.athletes" />
                </SecondaryButton>
                {availableFilters.map(filterConfig => {
                  const key = `SearchFiltersMobile.${filterConfig.scope || 'built-in'}.${filterConfig.key
                    }`;

                  return (
                    <FilterComponent
                      key={key}
                      idPrefix="SearchFiltersMobile"
                      config={filterConfig}
                      listingCategories={listingCategories}
                      marketplaceCurrency={marketplaceCurrency}
                      urlQueryParams={validQueryParams}
                      initialValues={initialValues(this.props, this.state.currentQueryParams)}
                      getHandleChangedValueFn={this.getHandleChangedValueFn}
                      intl={intl}
                      liveEdit
                      showAsPopup={false}
                      userType={userType}
                      pub_listingType={pub_listingType}
                    />
                  );
                })}
                {pub_listingType == PROMOTION ? null : <div>
                  <button className={css.locationSearchButtonMobile} onClick={() => { this.setState({ isLocationSearch: !this.state.isLocationSearch }) }}>
                    <FormattedMessage id="SearchPage.location" />
                  </button>
                  <div className={css.locationSearchInputMobile}>
                    {
                      this.state.isLocationSearch ?
                        <TopbarSearchForm
                          onSubmit={this.handleSubmit}
                          initialValues={topbarSearcInitialValues()}
                          isMobile
                          appConfig={config}
                        /> : null
                    }
                  </div>
                </div>}
              </SearchFiltersMobile>
              {pub_listingType == PROMOTION ? null : <MainPanelHeader
                className={css.mainPanel}
                sortByComponent={sortBy('desktop')}
                isSortByActive={sortConfig.active}
                listingsAreLoaded={listingsAreLoaded}
                resultsCount={totalItems}
                searchInProgress={searchInProgress}
                searchListingsError={searchListingsError}
                noResultsInfo={noResultsInfo}
              />}
              <div
                className={classNames(css.listingsForGridVariant, {
                  [css.newSearchInProgress]: !(listingsAreLoaded || searchListingsError),
                })}
              >
                {searchListingsError ? (
                  <H3 className={css.error}>
                    <FormattedMessage id="SearchPage.searchError" />
                  </H3>
                ) : null}
                {!isValidDatesFilter ? (
                  <H5>
                    <FormattedMessage id="SearchPage.invalidDatesFilter" />
                  </H5>
                ) : null}
                {pub_listingType == PROMOTION ? <div className={css.promotionHeading}><FormattedMessage id="SearchPage.promotion" /></div> : null}
                <SearchResultsPanel
                  className={css.searchListingsPanel}
                  listings={filterListing}
                  pagination={listingsAreLoaded ? pagination : null}
                  search={parse(location.search)}
                  isMapVariant={false}
                  currentUser={currentUser} 
                  pagePathParams={{tab: GET_SEARCH_TYPE_WITH_TAB[searchParams?.pub_listingType]}} 
                />  
              </div>
            </div>
          </div>
        </div>
        <FooterContainer />
      </Page>
    );
  }
}

SearchPageComponent.defaultProps = {
  listings: [],
  pagination: null,
  searchListingsError: null,
  searchParams: {},
};

SearchPageComponent.propTypes = {
  listings: array,
  onManageDisableScrolling: func.isRequired,
  pagination: propTypes.pagination,
  scrollingDisabled: bool.isRequired,
  searchInProgress: bool.isRequired,
  searchListingsError: propTypes.error,
  searchParams: object,

  // from useHistory
  history: shape({
    push: func.isRequired,
  }).isRequired,
  // from useLocation
  location: shape({
    search: string.isRequired,
  }).isRequired,

  // from useIntl
  intl: intlShape.isRequired,

  // from useConfiguration
  config: object.isRequired,

  // from useRouteConfiguration
  routeConfiguration: arrayOf(propTypes.route).isRequired,
};

const EnhancedSearchPage = props => {
  const config = useConfiguration();
  const routeConfiguration = useRouteConfiguration();
  const intl = useIntl();
  const history = useHistory();
  const location = useLocation();

  const searchListingsError = props.searchListingsError;
  if (isForbiddenError(searchListingsError)) {
    // This can happen if private marketplace mode is active
    return (
      <NamedRedirect
        name="SignupPage"
        state={{ from: `${location.pathname}${location.search}${location.hash}` }}
      />
    );
  }

  const { currentUser, ...restOfProps } = props;
  const isPrivateMarketplace = config.accessControl.marketplace.private === true;
  const isUnauthorizedUser = currentUser && !isUserAuthorized(currentUser);
  const hasNoViewingRightsUser = currentUser && !hasPermissionToViewData(currentUser);
  const hasUserPendingApprovalError = isErrorUserPendingApproval(searchListingsError);
  const hasNoViewingRightsError = isErrorNoViewingPermission(searchListingsError);

  if ((isPrivateMarketplace && isUnauthorizedUser) || hasUserPendingApprovalError) {
    return (
      <NamedRedirect
        name="NoAccessPage"
        params={{ missingAccessRight: NO_ACCESS_PAGE_USER_PENDING_APPROVAL }}
      />
    );
  } else if (hasNoViewingRightsUser || hasNoViewingRightsError) {
    return (
      <NamedRedirect
        name="NoAccessPage"
        params={{ missingAccessRight: NO_ACCESS_PAGE_VIEW_LISTINGS }}
      />
    );
  }

  return (
    <SearchPageComponent
      config={config}
      routeConfiguration={routeConfiguration}
      intl={intl}
      history={history}
      location={location}
      {...restOfProps}
      currentUser={currentUser}
    />
  );
};

const mapStateToProps = state => {
  const { currentUser } = state.user;
  const { isAuthenticated } = state.auth;
  const {
    currentPageResultIds,
    pagination,
    searchInProgress,
    searchListingsError,
    searchParams,
  } = state.SearchPage;
  const listings = getListingsById(state, currentPageResultIds);

  return {
    currentUser,
    listings,
    pagination,
    scrollingDisabled: isScrollingDisabled(state),
    searchInProgress,
    searchListingsError,
    searchParams,
    isAuthenticated
  };
};

const mapDispatchToProps = dispatch => ({
  onManageDisableScrolling: (componentId, disableScrolling) =>
    dispatch(manageDisableScrolling(componentId, disableScrolling)),
});

// Note: it is important that the withRouter HOC is **outside** the
// connect HOC, otherwise React Router won't rerender any Route
// components since connect implements a shouldComponentUpdate
// lifecycle hook.
//
// See: https://github.com/ReactTraining/react-router/issues/4671
const SearchPage = compose(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )
)(EnhancedSearchPage);

export default SearchPage;
